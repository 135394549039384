/* You can add global styles to this file, and also import other style files */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
}
